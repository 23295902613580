const { plugin } = require('twrnc');

module.exports = {
  theme: {
    colors: {
      'brand_primary': '#0478CC',
      'brand_secondary': '#4AA6E8',
      'brand_tertiary': '#D9EBF7',
      'blue': '#0478CC',
      'blueLight': '#4AA6E8',
      'blueGray': '#D9EBF7',
      'white': '#FFFFFF',
      'gray0': '#F9FAFC',
      'gray1': '#EFF2F7',
      'gray2': '#E5E9F2',
      'gray3': '#E0E6ED',
      'gray4': '#D3DCE6',
      'gray5': '#C0CCDA',
      'gray5_5': '#8492A6',
      'gray6': '#67758E',
      'gray7': '#6B6F8D',
      'black': '#000000',
      'ink': '#1F2D3D',
      'red': '#FD5C65',
      'redLight': '#FFDEE0',
      'orange': '#FA884C',
      'orangeLight': '#FEE7DB',
      'yellow': '#F2B339',
      'yellowLight': '#FCF0D7',
      'green': '#13CE8B',
      'greenLight': '#D0F5E8',
      'purple': '#8D7EFB',
      'purpleLight': '#EEECFE',
      'transparent': 'transparent'
    },
    fontFamily: {
      sans: ['Inter', 'sans-serif'],
      serif: ['Lora', 'serif'],
    },
    screens: {
      'sm': '640px',
      'md': '767px',
      'lg': '1024px',
    },
  },
  plugins: [
    plugin(({ addUtilities }) => {
      addUtilities({
        'transition-all': {
          transitionProperty: 'all',
          transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
          transitionDuration: '250ms',
        },
        'transition-opacity': {
          transitionProperty: 'opacity',
          transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
          transitionDuration: '250ms',
          willChange: 'opacity',
        },
        'select-none': {
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          WebkitTouchCallout: 'none',
          userSelect: 'none',
        },
        'outline-0': {
            outlineWidth: '0px',
        },
        'border-transparent': {
            borderColor: 'transparent',
        },
        'translate-y-1/2': {
            transform: 'translateY(50%)',
        },
        '-translate-y-1/2': {
            transform: 'translateY(-50%)',
        },
        'translate-x-1/2': {
            transform: 'translateX(50%)',
        },
        '-translate-x-1/2': {
            transform: 'translateX(-50%)',
        },
        'translate-z-1/2': {
            transform: 'translateZ(50px)',
        },
        '-translate-z-1/2': {
            transform: 'translateZ(-50px)',
        },
        'translate-y-full': {
            transform: 'translateY(100%)',
        },
        '-translate-y-full': {
            transform: 'translateY(-100%)',
        },
        'translate-x-full': {
            transform: 'translateX(100%)',
        },
        '-translate-x-full': {
            transform: 'translateX(-100%)',
        },
        'translate-z-full': {
            transform: 'translateZ(100%)',
        },
        '-translate-z-full': {
            transform: 'translateZ(-100%)',
        },
        'translate-y-0': {
            transform: 'translateY(0)',
        },
        'translate-x-0': {
            transform: 'translateX(0)',
        },
        'translate-z-0': {
            transform: 'translateZ(0)',
        },
        'scale-100': {
            transform: 'scale(1)',
        },
        'scale-110': {
            transform: 'scale(1.10)',
        },
        'transition-transform': {
            transitionProperty: 'transform',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDuration: '150ms',
        },
        'pointer-events-none': {
            pointerEvents: 'none',
        },
        'pointer-events-auto': {
            pointerEvents: 'auto',
        },
        'cursor-default': {
            cursor: 'default',
        },
        'cursor-pointer': {
            cursor: 'pointer',
        },
        'cursor-not-allowed': {
            cursor: 'not-allowed',
        },
        'fixed': {
            position: 'fixed',
        },
        'order-1': {
            order: '1',
        },
        'order-2': {
            order: '2',
        },
        'order-3': {
            order: '3',
        },
        'order-4': {
            order: '4',
        },
        'inline-block': {
            display: 'inline-block',
        },
        'inline': {
            display: 'inline',
        },
        'will-change-transform': {
            willChange: 'transform',
        },
        'backdrop-blur-none': {
            backdropFilter: 'blur(0)',
        },
        'backdrop-blur-sm': {
            backdropFilter: 'blur(4px)',
        },
        'backdrop-blur': {
            backdropFilter: 'blur(8px)',
        },
        'backdrop-blur-md': {
            backdropFilter: 'blur(12px)',
        },
        'backdrop-blur-lg': {
            backdropFilter: 'blur(16px)',
        },
        'backdrop-blur-xl': {
            backdropFilter: 'blur(24px)',
        },
        'backdrop-blur-2xl': {
            backdropFilter: 'blur(40px)',
        },
        'backdrop-blur-3xl': {
            backdropFilter: 'blur(64px)',
        },
        'leading-12.25': {
            lineHeight: '50px',
        },
        'leading-7.75': {
            lineHeight: '31px',
        },
        'w-max': {
            width: 'max-content',
        },
        'h-max': {
            height: 'max-content',
        },
        'box-border': {
            boxSizing: 'border-box',
        },
        'box-content': {
            boxSizing: 'content-box',
        },
        'overflow-x-hidden': {
            overflowX: 'hidden',
        },
        'h-min': {
            height: 'min-content',
        },
        'h-fit': {
            height: 'fit-content',
        },
        'min-h-max': {
            minHeight: 'max-content',
        },
        'truncate': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        'grayscale': {
            filter: 'grayscale(100%)',
        },
        'grayscale-0': {
            filter: 'grayscale(0%)',
        },
        'faded-ring': {
            boxShadow: 'inset 0 0 0 4px rgba(255,255,255,.7)',
        },
      });
    }),
  ],
}
