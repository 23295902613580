const RegExHelper = {
    validators:
    {
        names: /^[a-zA-Z ,.'-]{2,}$/,
        decimal: /^[0-9.]+$/,
        generic: /^([a-zA-Z0-9 ]{2,})+$/,
        email: /(?:[a-z0-9+!#$%&'*\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/i,
        hin: /^([a-zA-Z0-9-]{12,15})$/,
        uscg: /^([0-9]{6,7})+$/,
        registration: /^([a-zA-Z0-9 ]{6,})+$/,
        mmsi: /^([0-9]{9})+$/,
        imo: /^(IMO[0-9]{7}|[0-9]{7})$/,
        callsign: /^([a-zA-Z0-9]{3,9})+$/,
        eprib: /^([a-zA-Z0-9]{5}){3,4}$/,
        model_no: /^([a-zA-Z0-9 -.]{2,})+$/,
        serial_no: /^([a-zA-Z0-9 -.]{2,})+$/,
        part_no: /^([a-zA-Z0-9 -.]{2,})+$/,
        url: /^(https?:\/\/)([a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+)(:\d+)?(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$/,
    },
    restrictions:
    {
        numbers: /^[0-9]*$/,
        characters: /^[a-zA-Z]*$/,
        alphanumeric: /^[a-zA-Z0-9]*$/,
    },
    maxLengths:
    {
        names: 50,
        decimal: 10,
        generic: 50,
        email: 50,
        hin: 15,
        uscg: 7,
        registration: 50,
        mmsi: 9,
        imo: 7,
        callsign: 9,
        eprib: 20,
        model_no: 50,
        serial_no: 50,
        part_no: 50,
    },
}

export default RegExHelper
