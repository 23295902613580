import React from 'react';
import { View, ScrollView, Pressable, Image, useWindowDimensions } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, Span, TextInput, Button, AssetItem, PillMenu, SelectMenu, Modal, Notification, HorizontalRule, AddNewAssetButtons, DocumentUploadInput, SpecsCard, Tag } from '../elements';
import { useSpring, animated } from 'react-spring';
import { getInitials, goPrevScreen, getUnreadNotificationsCount, getPropertiesByGroup, getOptionsByLabelGroup, sort_by, canIhazIcon, getShares, truncate, now, is_date_in_past, is_date_within_30_days, date_DMJYYYY, YYYYMMDD_to_DMJYYYY, YYYYMMDD_to_MMDDYYYY, MMDDYYYY_to_YYYYMMDD, getLabel, date_MMDDYYYY, processDocumentInput, uploadDocument, getAssetDocuments } from '../helpers';
import RegExHelper from '../regex';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function ShareYachtView ({route, navigation})
{
    const AnimatedView = animated(View);
    const tw = create( require(`../../tailwind.config.js`) );    
    useDeviceContext(tw);
    const {height, width} = useWindowDimensions();
    const formHeight = height - 100;
    const toast = useToast();
    
    let {
        apiUrl,
        apiVersion,
        settings,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userYachts,
        userNotifications,
        enabledFeatures
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {        
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    React.useEffect(()=>
    {
        if ( ! enabledFeatures.sharing ) { navigation.navigate('yachts'); }
    },[enabledFeatures]);

    const labels = React.useRef(null);
    React.useEffect(()=>
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSETS: getLabel('CREW_MEMBERS'),
                _ASSET: getLabel('CREW_MEMBER'),
                _404_TEXT: getLabel('SHARE_404_TEXT'),

                EDIT: getLabel('EDIT'),
                DELETE: getLabel('DELETE'),
                CANCEL: getLabel('CANCEL'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                CONTENT_ADD: getLabel('CONTENT_ADD'),
                ADD: getLabel('ADD'),
                UPDATE: getLabel('UPDATE'),

                NAME: getLabel('NAME'),
                EMAIL: getLabel('CREATE_HINT_EMAIL'),
                SHARE_ROLE: getLabel('SHARE_ROLE'),
                SHARE_ACCEPTED: getLabel('SHARE_ACCEPTED'),
                SHARE_PENDING: getLabel('SHARE_PENDING'),
                SHARE_INVITED: getLabel('SHARE_INVITED'),
                SHARE_REJECTED: getLabel('SHARE_REJECTED'),
                SHARE_RESEND: getLabel('SHARE_RESEND'),

                SELECT: getLabel('SELECT'),
                SELECTED: getLabel('SELECTED'),
                REQUEST: getLabel('REQUEST'),
                REQUEST_VALID: getLabel('REQUEST_VALID'),
                DELETE_CONFIRM_TITLE: getLabel('DELETE_CONFIRM_TITLE'),
                DELETE_CONFIRM_BODY: getLabel('DELETE_CONFIRM_BODY'),
                PERMANENT_ACTION: getLabel('PERMANENT_ACTION'),
                REQUIRED: getLabel('REQUIRED'),

                TOAST_UPDATE: getLabel('TOAST_UPDATE'),
                TOAST_ADD: getLabel('TOAST_ADD'),
                TOAST_DELETE: getLabel('TOAST_DELETE'),

                DOCUMENTS: getLabel('DOCUMENTS'),
                DOCUMENT: getLabel('DOCUMENT'),
                _404_DOCUMENTS_TEXT: getLabel('DOCUMENT_404_TEXT'),
                DOCUMENT_FILE: getLabel('ATTACHMENT_FILE'),
                DOCUMENT_TOO_LARGE_BODY: getLabel('ATTACHMENT_TOO_LARGE_BODY'),
                DATE: getLabel('DATE'),
                NAME: getLabel('NAME'),
                EXPIRES: getLabel('EXPIRES'),
                EXPIRING: getLabel('EXPIRING'),
                EXPIRED: getLabel('EXPIRED'),
                EXPIRATION_NONE: getLabel('EXPIRATION_NONE'),
                EXPIRATION_VALID: getLabel('EXPIRATION_VALID'),
                EXPIRATION: getLabel('EXPIRATION'),
                TYPE: getLabel('TYPE'),
                DETAILS: getLabel('DETAILS'),
                VIEW: getLabel('VIEW'),
                DETAIL: getLabel('DETAIL'),
            };
        }
    },[]);

    const [notificationCount, setNotificationCount] = React.useState(0);
    React.useEffect(()=>
    {
        setNotificationCount(getUnreadNotificationsCount())
    },[userNotifications]);

    const { yachtID } = route.params;
    const [ yacht, setYacht ] = React.useState(0);
    
    const [primaryMsg, setPrimaryMsg] = React.useState('');
    const [primaryMsgType, setPrimaryMsgType] = React.useState('warning');
    const [modalActive, setModalActive] = React.useState(false);
    const [modalAffectID, setModalAffectID] = React.useState(0);

    const [addingNew, setAddingNew] = React.useState(false);
    const [updateID, setUpdateID] = React.useState(0);
    const [updateUserID, setUpdateUserID] = React.useState(0);

    const [viewingAsset, setViewingAsset] = React.useState(false);
    const [viewingAssetObj, setViewingAssetObj] = React.useState({});

    const [newAssetMsg, setNewAssetMsg] = React.useState('');
    const [newAssetMsgType, setNewAssetMsgType] = React.useState('warning');
    const [newAssetValidation, doNewAssetValidation] = React.useState(false);
    const [getAssets, setAssets] = React.useState([]);
    
    // asset specific input parameters
    const [newShareName, setNewShareName] = React.useState('');
    const [newShareEmail, setNewShareEmail] = React.useState('');
    const [newShareRole, setNewShareRole] = React.useState('');
    const [shareRoleSelectVisible, setShareRoleSelectVisible] = React.useState(false);

    // input refs for asset specific inputs
    const newShareEmailInput = React.useRef(null);

    // select menu options
    const roleAllOptions = getPropertiesByGroup( 'identifier', 'types.security.roles', 'label', false, true );
    const excludedRoleOptions = getPropertiesByGroup( 'identifier', 'types.security.roles', 'enabled_app', false, true ).filter(obj => {return obj.enabled_app === 0});
    const roleOptions = roleAllOptions.filter(obj => {return 'ROLE_NONE' !== obj.value && ! excludedRoleOptions.find(excludedObj => {return excludedObj.value === obj.value})});

    // related resources inputs
    const photoObjectType = React.useRef('obj_share');
    const [contentType, setContentType] = React.useState(getLabel('DETAILS'));
    const [deleteFileModalActive, setDeleteFileModalActive] = React.useState(false);
    const [deleteFileModalAffectID, setDeleteFileModalAffectID] = React.useState('');
    const iDsContainingFiles = React.useRef([]);

    // document specific inputs
    const [documentName, setDocumentName] = React.useState('');
    const [documentDesc, setDocumentDesc] = React.useState('');
    const [documentType, setDocumentType] = React.useState(0);
    const [documentExpiration, setDocumentExpiration] = React.useState('');
    const [documentExpirationMasked, setDocumentExpirationMasked] = React.useState('');
    const [documentTypeSelectVisible, setDocumentTypeSelectVisible] = React.useState(false);
    const [updateDocument, setUpdateDocument] = React.useState(false);
    const [updateDocumentID, setUpdateDocumentID] = React.useState(0);
    const [documents, setDocuments] = React.useState([]);
    const [documentData, setDocumentData] = React.useState('');
    const documentMimeType = React.useRef('');
    const [viewingDocument, setViewingDocument] = React.useState(false);
    const [viewingDocumentObj, setViewingDocumentObj] = React.useState({});
    const documentSize = React.useRef(0);
    const documentExt = React.useRef('');
    const documentInput = React.useRef(null);
    const documentNameInput = React.useRef(null);
    const documentFileNameInput = React.useRef(null);
    const documentDescInput = React.useRef(null);
    const documentExpirationInput = React.useRef(null);
    const documentTypeOptions = getOptionsByLabelGroup('types.attachment.types', true);
    const documentTypeIcons = getPropertiesByGroup( 'value', 'types.attachment.types', 'icon', false, true );
    const prepareAssetDocuments = React.useCallback((asset, editable)=>
    {
        if ( ! enabledFeatures.documents ) { return false; }

        let yachtObj = false;
        userYachts.every((value, index) => {
            if ( yachtID == value.id ) { yachtObj = value; return false; }
            return true;
        });

        let documentAssets = [];

        if ( getAssetDocuments(yachtID, asset.id, userYachts).length )
        {
            let documents = getAssetDocuments(yachtID, asset.id, userYachts).sort(sort_by('modified_date', true));
            documents.forEach((file, index) => {
                let icon = file.type_id ? documentTypeIcons.find(obj => {return obj.value == file.type_id})?.icon : 'miscellaneous';
                let icon_variant = '_white';
                icon = icon + icon_variant;
                icon = canIhazIcon( icon ) ? icon : 'miscellaneous' + icon_variant;
                file.expiration_tag = file.expiration_date ? ( is_date_within_30_days(file.expiration_date) ? ((is_date_in_past(file.expiration_date) ? labels?.current?.EXPIRED : labels?.current?.EXPIRING) ) : labels?.current?.EXPIRATION_VALID ) : labels?.current?.EXPIRATION_NONE;
                file.expiration_color = file.expiration_date ? ( is_date_within_30_days(file.expiration_date) ? ((is_date_in_past(file.expiration_date) ? 'red' : 'orange') ) : 'green' ) : 'gray5_5';
                documentAssets.push(
                    <AssetItem
                        key={index}
                        style={[editable === true && {width:'calc(100% - 18px)'}, tw`md:w-76`]}
                        minHeight={73}
                        color='blue'
                        icon={{ uri: require('../svg/'+icon+'.svg') }}
                        title={ file.type_id ? documentTypeOptions.find(obj => {return obj.value == file.type_id})?.label : '' }
                        description={ file.name }
                        description2={ file.expiration_date ? file.expiration_tag +': '+YYYYMMDD_to_DMJYYYY(file.expiration_date) : ' ' }
                        tag={ file.expiration_tag }
                        tagColor={ file.expiration_color }
                        // description2={ file.mime_type }
                        menu={editable === true && [
                            { name:labels.current?.EDIT, color:'green', onPress: ()=>{
                                setUpdateDocumentID(file.id);
                                setDocumentName(file.name); documentNameInput.current.value = file.name;
                                setDocumentDesc(file.details); documentDescInput.current.value = file.details;
                                setDocumentExpiration(YYYYMMDD_to_MMDDYYYY(file.expiration_date)); documentExpirationInput.current.value = YYYYMMDD_to_MMDDYYYY(file.expiration_date);
                                setDocumentExpirationMasked(YYYYMMDD_to_MMDDYYYY(file.expiration_date));
                                setDocumentType(file.type_id);
                                setUpdateDocument(true);
                            }},
                            { name:labels.current?.DELETE, color:'red', onPress: ()=>{
                                setDeleteFileModalAffectID(file.id);
                                documentMimeType.current = file.mime_type;
                                setDeleteFileModalActive(true);
                            }}
                        ]}
                        onPress={() => {
                            setViewingDocumentObj(file);
                            setViewingDocument(true);
                        }}
                        viewMore={labels.current?.VIEW}
                    />
                )
            });
        }

        return documentAssets;
    },[labels, userYachts, setUpdateDocumentID, setDocumentName, setDocumentExpiration, setDocumentDesc, setDocumentType, setUpdateDocument, documentNameInput, documentExpirationInput, documentDescInput, setDeleteFileModalAffectID, setDeleteFileModalActive, enabledFeatures]);
    let addNewDocumentPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: updateDocument ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: updateDocument ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewDocumentPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: updateDocument ? 1 : 0,
            pointerEvents: updateDocument ? 'auto' : 'none',
        },
        config: updateDocument ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewDocumentPaneAnimation = tw.prefixMatch('md') ? addNewDocumentPaneAnimationDesktop : addNewDocumentPaneAnimation;

    let viewDocumentAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingDocument ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingDocument ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    let viewDocumentAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
        },
        to: {
            opacity: viewingDocument ? 1 : 0,
            pointerEvents: viewingDocument ? 'auto' : 'none',
        },
        config: viewingDocument ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    viewDocumentAnimation = tw.prefixMatch('md') ? viewDocumentAnimationDesktop : viewDocumentAnimation;

    React.useEffect(() => {
        let assets = [];

        if ( yachtID )
        {
            let yachtObj = false;
            userYachts.every((value, index) => {
                if ( yachtID == value.id ) { setYacht(value); yachtObj = value; return false; }
                return true;
            });

            let shares = getShares(yachtID);

            shares.forEach((asset, index) => {
                documents[asset.user_id] = prepareAssetDocuments(asset, 0 !== updateUserID);
                if ( asset.documents?.length > 0 && ! iDsContainingFiles.current.includes(asset.user_id) ) { iDsContainingFiles.current.push(asset.user_id); }
                documents[asset.id] = prepareAssetDocuments(asset, 0 !== updateUserID);
                if ( asset.documents?.length > 0 && ! iDsContainingFiles.current.includes(asset.id) ) { iDsContainingFiles.current.push(asset.id); }

                let menu = [];

                if ( yachtObj.is_owned_by_me ) {
                    menu = [
                        { name:labels.current?.EDIT, color:'green', onPress: ()=>{
                            setUpdateID(asset.id);
                            setUpdateUserID(asset.user_id);
                            setNewShareEmail(asset.email);
                            setNewShareName(asset.name);
                            if ( newShareEmailInput.current?.value ) { newShareEmailInput.current.value = asset.email; }
                            setNewShareRole(asset.security_role);
                            setContentType(labels.current?.DETAILS);
                            documents[asset.user_id] = prepareAssetDocuments(asset, true);

                            setAddingNew(true);
                            // doNewAssetValidation(true);
                            // setTimeout(()=>{
                            //     doNewAssetValidation(false);
                            //     setAddingNew(true);
                            // },250);
                        }},
                        ...(! asset.is_owner ? [{ name:labels.current?.DELETE, color:'red', onPress: ()=>{
                            setModalAffectID(asset.user_id);
                            setModalActive(true) }
                        }] : [])
                    ]
                }

                assets.push(
                    <AssetItem
                        key={asset.user_id}
                        title={asset.name}
                        subtitle={ getAssetDocuments(yachtID, asset.id, userYachts)?.length > 0 &&
                            <View style={tw`flex-row`}>
                                { getAssetDocuments(yachtID, asset.id, userYachts)?.length > 0 && enabledFeatures.documents &&
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/attachment_lightgray.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-4 w-4 mt-1 ml-2`}
                                    />
                                }
                            </View>
                        }
                        description={asset.security_role_name}
                        initials={getInitials(asset.name)}
                        color={'accepted' === asset.status ? 'green' : ( 'rejected' === asset.status ? 'red' : 'blue' ) }
                        email={asset.email}
                        tag={asset.is_owner ? ' ' : ('accepted' === asset.status ? labels.current?.SHARE_ACCEPTED : ( 'rejected' === asset.status ? labels.current?.SHARE_REJECTED : labels.current?.SHARE_INVITED ))}
                        tagColor={asset.is_owner ? 'white' : ('accepted' === asset.status ? 'green' : ( 'rejected' === asset.status ? 'red' : 'blue' )) }

                        menu={menu}
                        onPress={() => {
                            setContentType(labels.current?.DETAILS);
                            documents[asset.user_id] = prepareAssetDocuments(asset, false);
                            setViewingAssetObj(asset);
                            setViewingAsset(true);
                        }}
                    />
                )
            });
        }

        setAssets(assets);
    },[yachtID, userYachts, labels]);
    

    let addNewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: addingNew ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: addingNew ? 1 : 0,
            pointerEvents: addingNew ? 'auto' : 'none',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewPaneAnimation = tw.prefixMatch('md') ? addNewPaneAnimationDesktop : addNewPaneAnimation;

    let viewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingAsset ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let viewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: viewingAsset ? 1 : 0,
            pointerEvents: viewingAsset ? 'auto' : 'none',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    viewPaneAnimation = tw.prefixMatch('md') ? viewPaneAnimationDesktop : viewPaneAnimation;

    return (
        <View label="shareYacht" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} hideOnMobile={true} yachtID={yachtID} />

            <View style={tw`bg-blue h-20 flex-row md:hidden`}>
                <View style={tw`bg-blue h-20 p-5 pt-6.5 flex-row`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => { goPrevScreen(navigation, 'yacht', {yachtID: yachtID}) }}
                    >
                        <Image
                            accessibilityLabel={ labels.current?.CANCEL }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px mr-1`}
                        />
                    </Pressable>
                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-sm font-semibold shrink-0 w-8 h-8 mr-3 text-center leading-[31px] -mt-[2px]`}>{yacht.initials}</Span>
                    <Heading style={tw`mt-1.5 leading-4 mb-0 text-white`} hlevel={1} styleHlevel={4}>
                        { labels.current?._ASSETS }
                    </Heading>
                </View>

                <View style={tw`grow`}></View>
                
                <View style={tw`w-18 justify-center md:order-2 md:w-full`}>
                    <Pressable
                        onPress={()=>{navigation.navigate('notifications')}}
                        style={tw`p-5 relative md:flex-row md:pl-15 md:py-3 md:my-2`}
                    >
                        { notificationCount > 0 &&
                            <Span style={tw`bg-white text-blue rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-18 z-10 pointer-events-none`}>
                                { notificationCount }
                            </Span>
                        }
                        
                        <Image
                            accessibilityLabel={ labels.current?.NOTIFICATIONS }
                            source={{ uri: require('../svg/notifications.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                        />

                        <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ labels.current?.NOTIFICATIONS }</Span>
                        
                    </Pressable>
                </View>
            </View>
        
            <View style={tw`bg-blue h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>

                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >

                        <View style={tw`hidden md:flex`}>
                                <View style={tw`flex-row w-full items-center justify-between lg:justify-start mt-2 md:mt-6 lg:mt-10 mb-0 md:mb-1 lg:mb-5 md:px-1`}>
                                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-lg font-semibold shrink-0 w-14 h-14 mr-3 text-center leading-[56px]`}>{yacht.initials}</Span>

                                    <Heading style={tw`leading-4 hidden md:flex`} hlevel={1} styleHlevel={2}>
                                        {yacht.name}
                                    </Heading>

                            </View>
                        </View>

                        { '' !== primaryMsg &&
                            <View style={tw`w-full px-4 md:px-1`}>
                                <Notification
                                    style={tw`my-4`}
                                    type={primaryMsgType}
                                >{primaryMsg}</Notification>
                            </View>
                        }
                        { getAssets.length < 1 &&
                            <View style={tw`justify-center grow items-center`}>

                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/shared_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-32 w-32 md:h-48 md:w-48`}
                                />
                                <Heading style={tw`text-gray5 text-center p-5 mb-26`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ getShares(yachtID)?.length > 0 ? getLabel('SEARCH_NOTFOUND_TITLE') : labels.current?._404_TEXT }</Heading>

                                { true === yacht.is_owned_by_me &&
                                    <View style={tw`fixed bottom-9 md:bottom-18 right-18 pointer-events-none`}>

                                        <Span style={tw`text-sm md:text-base font-medium font-sans mb-2 mr-6`}>{ labels.current?.CONTENT_ADD }</Span>

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/arrow_organic.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-9 w-18 left-14 md:left-16`}
                                        />
                                    </View>
                                }
                            </View>
                        }
    
                        { getAssets.length > 0 &&
                            <View>
                                <Heading hlevel={2} styleHlevel={4} style={tw`hidden md:flex`}>{ labels.current?._ASSETS }</Heading>

                                <View style={tw`md:pl-1 md:flex-wrap md:flex-row mb-20 md:justify-start md:items-start`}>
                                    {getAssets}
                                </View>
                            </View>
                        }
                        
                    </ScrollView>

                    { true === yacht.is_owned_by_me &&
                        <AddNewAssetButtons addNewLabel={ labels.current?.ADD +' '+ labels.current?._ASSET } onAddNew={()=>{
                            setUpdateID(0);
                            setUpdateUserID(0);
                            setContentType(labels?.current?.DETAILS);
                            setNewAssetMsg('');
                            setNewShareEmail(''); if ( newShareEmailInput?.current?.value ) { newShareEmailInput.current.value = '' };
                            setNewShareName('');
                            setNewShareRole('');

                            // doNewAssetValidation(true);
                            setTimeout(()=>{
                                // doNewAssetValidation(false);
                                setAddingNew(true);
                            }, 250);
                        }} />
                    }
                </View>
            </View>

            <AnimatedView style={addNewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>
                
                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateUserID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?._ASSET }</Heading>
                    </View>
                
                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>
        
                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>

                            { 0 !== updateUserID &&
                                <PillMenu
                                    style={tw`w-auto mx-5.5 mb-3 md:mb-4 md:-mt-2`}
                                    selected={ contentType }
                                    menu={[
                                        { name:labels.current?.DETAILS, icon:{ uri: require('../svg/notes_white.svg') }, inactiveIcon:{ uri: require('../svg/notes.svg') }, onPress:()=>{ setContentType(labels.current?.DETAILS); } },
                                        ...(enabledFeatures.documents ? [{ name: labels.current?.DOCUMENTS, icon: { uri: require('../svg/attachment_white.svg') }, inactiveIcon: { uri: require('../svg/attachment.svg') }, onPress: () => { setContentType(labels.current?.DOCUMENTS); } }] : [])
                                    ]}
                                />
                            }

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between`, labels?.current?.DETAILS !== contentType && tw`hidden`]}>
                                    {updateUserID === 0 ?
                                        <TextInput
                                            style={tw`mt-2 md:w-48.5/100`}
                                            autoCapitalize="off"
                                            placeholder={ labels.current?.EMAIL +'*' }
                                            image={ newShareEmail ? { uri: require('../svg/email_blue.svg') } : { uri: require('../svg/email_gray.svg') }}
                                            keyboardType="email-address"
                                            pattern="^\S+@\S+\.\S+$"
                                            validatedValue={(value) => {setNewShareEmail(value)}}
                                            validateOnChange={true}
                                            passedRef={newShareEmailInput}
                                            required
                                        />
                                    :
                                        <View style={tw`w-full`}>
                                            <SpecsCard style={tw`w-full`} label={ labels.current?.NAME } labelImage={{ uri: require('../svg/name_blue.svg') }} value={newShareName} />
                                            <SpecsCard style={tw`w-full`} label={ labels.current?.EMAIL } labelImage={{ uri: require('../svg/email_blue.svg') }} value={newShareEmail} />
                                            { 'ROLE_OWNER' === newShareRole && <SpecsCard style={tw`w-full mb-5`} label={ labels.current?.SHARE_ROLE } labelImage={{ uri: require('../svg/setting_blue.svg') }} value={roleAllOptions.find(obj => {return obj.value == newShareRole}).label} /> }
                                            <HorizontalRule style={[tw`mt-4 mb-3 -left-5 w-screen md:w-full md:left-0`, 'ROLE_OWNER' === newShareRole && tw`hidden`]} />
                                        </View>

                                    }
                                    
                                    { 'ROLE_OWNER' !== newShareRole &&
                                        <Pressable
                                            style={[tw`mt-2`, updateUserID === 0 ? tw`md:w-48.5/100` : tw`md:w-full`]}
                                            onPress={()=>{setShareRoleSelectVisible(true)}}
                                        >
                                            <TextInput
                                                style={tw`pointer-events-none`}
                                                placeholder={ labels.current?.SHARE_ROLE +'*' }
                                                image={ newShareRole ? { uri: require('../svg/setting_blue.svg') } : { uri: require('../svg/setting_gray.svg') }}
                                                value={newShareRole ? roleAllOptions.find(obj => {return obj.value == newShareRole}).label : ''}
                                                required
                                            />

                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                            />
                                        </Pressable>
                                    }

                                    <Span style={[tw`text-sm text-gray6 my-2 w-full`, 'ROLE_OWNER' === newShareRole && tw`hidden`]}>{ '* ' + labels.current?.REQUIRED }</Span>
                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:pb-5`, ( labels?.current?.DOCUMENTS !== contentType || ! updateUserID ) && tw`hidden`]}>

                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`pt-4 rounded bg-white border border-gray4 mt-2`, documents[updateUserID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 200}px] md:h-[272px]`}
                                    >
                                        { documents[updateUserID]?.length ? <View style={tw`md:flex-wrap md:flex-row pb-16 md:justify-start md:items-start`}>{ documents[updateUserID] }</View> : <View></View> }
                                        { ! documents[updateUserID]?.length &&
                                            <View style={tw`justify-center grow items-center mt-4`}>
                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/attachment_lightgray.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-28 w-28 md:h-40 md:w-40`}
                                                />
                                                <Heading style={tw`text-gray5 text-center p-5`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ labels.current?._404_DOCUMENTS_TEXT }</Heading>
                                            </View>
                                        }
                                    </ScrollView>

                                    <DocumentUploadInput
                                        passedRef={documentInput}
                                        onChange={(e)=>{
                                            if ( ! e.target.files[0] ) { return; }
                                            // if size is > 30 MB
                                            if ( e.target.files[0].size > ( settings?.max_size_document ? Math.round(parseInt(settings.max_size_document, 10)) : 31457280 ) ) { toast.show(labels.current?.DOCUMENT_TOO_LARGE_BODY.replace('%size%', '30 MB'), {type: 'danger', duration:10000}); return; }

                                            documentFileNameInput.current.value = e.target.files[0].name;
                                            processDocumentInput(e.target.files[0]).then((result)=>{
                                                setDocumentData(result);
                                                documentMimeType.current = e.target.files[0].type;
                                                documentSize.current = e.target.files[0].size;
                                                documentExt.current = e.target.files[0].name.split('.').pop().toLowerCase();
                                            }).catch((error)=>{
                                                toast.show(error, {type: 'danger', duration:10000});
                                            });
                                        }}
                                    />

                                    <AddNewAssetButtons style={tw`bottom-10 right-9 md:right-10`} hideRefresh={true} hideTooltips={true} addNewLabel={ labels.current?.ADD +' '+ labels.current?.DOCUMENT } onAddNew={()=>{
                                        setUpdateDocumentID(0);

                                        setDocumentData('');
                                        documentSize.current = 0;
                                        documentExt.current = '';
                                        if ( documentFileNameInput.current?.value ) { documentFileNameInput.current.value = ''; }
                                        documentMimeType.current = '';
                                        setDocumentName('');
                                        if ( documentNameInput.current?.value ) { documentNameInput.current.value = ''; }
                                        setDocumentExpiration('');
                                        setDocumentExpirationMasked('');
                                        if ( documentExpirationInput.current?.value ) { documentExpirationInput.current.value = ''; }
                                        setDocumentType(0);
                                        setDocumentDesc('');
                                        if ( documentDescInput.current?.value ) { documentDescInput.current.value = ''; }

                                        setUpdateDocument(true);
                                    }} />

                                </View>
                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />

                            <Button
                                style={[tw`m-5`, labels?.current?.DETAILS !== contentType && tw`hidden`, 'ROLE_OWNER' === newShareRole && tw`hidden`]}
                                title={0 !== updateUserID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={newShareEmail && newShareRole ? false : true}
                                validateGroup={newAssetValidation}
                                onPress={()=>{
                                    console.log('Added/Updated user:');
                                    console.log({
                                        vessel_id: yachtID,
                                        email: newShareEmail,
                                        security_role: newShareRole,
                                    });

                                    if ( newAssetValidation ) { return; }
                                    
                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');
                                    setPrimaryMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        if ( ! newShareEmail
                                            || ! newShareRole
                                        )
                                        {
                                            let errorMsg = [];

                                            if ( ! newShareEmail )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.EMAIL); }
                                            if ( ! newShareRole )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.SHARE_ROLE); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            let payload = {
                                                vessel_id: yachtID,
                                                email: newShareEmail,
                                                security_role: newShareRole,
                                            };
                                            if ( 0 !== updateUserID ) { payload.user_id = updateUserID; delete payload.email; }

                                            fetch(apiUrl, {
                                                method: 'POST',
                                                cache: 'no-cache',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    endpoint: 0 !== updateUserID ? 'vessels/share/update' : 'vessels/share/add', v: apiVersion,
                                                    device_id: localStorage.getItem('deviceID'),
                                                    client_key: localStorage.getItem('clientKey'),
                                                    payload: payload
                                                }),
                                            })
                                            .then((response) => {
                                                return response.text().then(function(text) {
                                                    const result = JSON.parse(text);
                                                    if ( result.error )
                                                    {
                                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                        // setNewAssetMsgType('warning');
                                                        // setNewAssetMsg(result.error);
                                                        toast.show(result.error, {type: 'danger', duration:10000});
                                                        doNewAssetValidation(false);
                                                    }
                                                    else
                                                    {
                                                        setAddingNew(false);
                                                        doNewAssetValidation(false);
                                                        if ( result.msg )
                                                        {
                                                            // setPrimaryMsgType('success');
                                                            // setPrimaryMsg(result.msg);
                                                            toast.show(result.msg, {type: 'success', duration:10000});
                                                        }
                                                        else {
                                                            // setPrimaryMsgType('success');
                                                            // setPrimaryMsg(0 !== updateUserID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD);
                                                            toast.show((0 !== updateUserID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}', labels.current?._ASSET), {type: 'success', duration:2000});
                                                        }

                                                        setTimeout(()=>{
                                                            setLastUpdate(now()); // forces app to refetch all account data
                                                        }, 500);

                                                        setTimeout(()=>{
                                                            setNewShareEmail(''); if ( newShareEmailInput?.current?.value ) { newShareEmailInput.current.value = '' };
                                                            setNewShareRole('');
                                                        }, 1500);
                                                    }
                                                });
                                            })
                                            .catch(error => {
                                                console.warn(error);
                                                setNewAssetMsgType('warning');
                                                if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                else { setNewAssetMsg('An error ocurred. See console log.'); }
                                                doNewAssetValidation(false);
                                            });
                                        }
                                    }, 250);

                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={viewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingAsset(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingAsset(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={2}>{ viewingAssetObj.name }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                { /* 0 !== viewingAssetObj.is_private &&
                                    <Span style={tw`text-gray6 text-sm shrink-0 text-right`}>{ labels.current?.PRIVATE }</Span>
                                */ }

                                <View style={tw`flex-col justify-center items-end`}>
                                    <Span style={tw`text-gray6 text-sm leading-6 text-right`}>
                                        { viewingAssetObj.accept_date ? date_MMDDYYYY( viewingAssetObj.accept_date ) : '' }
                                    </Span>
                                    { ! viewingAssetObj.is_owner &&
                                        <Tag color={ 'accepted' === viewingAssetObj.status ? 'green' : ( 'rejected' === viewingAssetObj.status ? 'red' : 'blue' ) }>
                                            { 'accepted' === viewingAssetObj.status ? labels.current?.SHARE_ACCEPTED : ( 'rejected' === viewingAssetObj.status ? labels.current?.SHARE_REJECTED : labels.current?.SHARE_INVITED ) }
                                        </Tag>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View>
                                    <Span style={tw`font-bold`}>{ viewingAssetObj.name }</Span>
                                    <View style={tw`flex-col justify-center items-start`}>
                                        <Span style={tw`text-gray6 text-sm leading-6`}>
                                            { viewingAssetObj.accept_date ? date_MMDDYYYY( viewingAssetObj.accept_date ) : '' }
                                        </Span>
                                        { ! viewingAssetObj.is_owner &&
                                            <Tag style={tw`mt-2`} color={ 'accepted' === viewingAssetObj.status ? 'green' : ( 'rejected' === viewingAssetObj.status ? 'red' : 'blue' ) }>
                                                { 'accepted' === viewingAssetObj.status ? labels.current?.SHARE_ACCEPTED : ( 'rejected' === viewingAssetObj.status ? labels.current?.SHARE_REJECTED : labels.current?.SHARE_INVITED ) }
                                            </Tag>
                                        }
                                    </View>
                                </View>

                                { /* false !== viewingAssetObj.is_private &&
                                    <View>
                                        <Span style={tw`text-gray6 text-sm md:mr-5 shrink-0`}>{ labels.current?.PRIVATE }</Span>
                                    </View>
                                */ }
                            </View>
                            <HorizontalRule style={tw`mb-4 md:hidden`} />

                            { ( 0 !== documents[viewingAssetObj.id]?.length ) &&
                                <PillMenu
                                    style={tw`w-auto mx-5.5 mb-3 md:mb-4 md:-mt-2`}
                                    selected={ contentType }
                                    menu={[
                                        { name:labels.current?.DETAILS, icon:{ uri: require('../svg/notes_white.svg') }, inactiveIcon:{ uri: require('../svg/notes.svg') }, onPress:()=>{ setContentType(labels.current?.DETAILS); } },
                                        ...(enabledFeatures.documents && 0 !== documents[viewingAssetObj.id]?.length ? [{ name: labels.current?.DOCUMENTS, icon: { uri: require('../svg/attachment_white.svg') }, inactiveIcon: { uri: require('../svg/attachment.svg') }, onPress: () => { setContentType(labels.current?.DOCUMENTS); } }] : [])
                                    ]}
                                />
                            }

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5`}
                            >
                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between content-start md:min-h-[300px]`, labels?.current?.DETAILS !== contentType && tw`hidden`]}>
                                    <View style={tw`md:flex-row md:flex-wrap md:px-1 w-full max-w-full`}>
                                        <SpecsCard
                                            style={tw`w-full`}
                                            label={ labels.current?.SHARE_ROLE }
                                            labelImage={{ uri: require('../svg/setting_blue.svg') }}
                                            value={viewingAssetObj.security_role_name}
                                        />
                                        <SpecsCard
                                            style={tw`w-full mt-2`}
                                            label={ labels.current?.EMAIL }
                                            labelImage={{ uri: require('../svg/email_blue.svg') }}
                                            value={viewingAssetObj.email}
                                        />
                                    </View>
                                    { ( 'pending' === viewingAssetObj.status || 'rejected' === viewingAssetObj.status ) &&
                                        <View>
                                            <Button
                                                style={tw`my-5`}
                                                title={labels.current?.SHARE_RESEND}
                                                validateGroup={newAssetValidation}
                                                onPress={()=>{
                                                    console.log('reshared with user:');
                                                    console.log({
                                                        vessel_id: yachtID,
                                                        email: viewingAssetObj.email,
                                                        security_role: viewingAssetObj.security_role,
                                                    });

                                                    if ( newAssetValidation ) { return; }

                                                    doNewAssetValidation(true);
                                                    setNewAssetMsg('');

                                                    setTimeout(()=>
                                                    {
                                                        // check if fields are valid

                                                        if ( ! viewingAssetObj.email
                                                            || ! viewingAssetObj.security_role
                                                        )
                                                        {
                                                            let errorMsg = [];

                                                            if ( ! viewingAssetObj.email )
                                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.EMAIL); }
                                                            if ( ! viewingAssetObj.security_role )
                                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.SHARE_ROLE); }

                                                            setNewAssetMsgType('warning');
                                                            setNewAssetMsg(errorMsg.join('\n'));
                                                            doNewAssetValidation(false);
                                                        }
                                                        else
                                                        {
                                                            let payload = {
                                                                vessel_id: yachtID,
                                                                email: viewingAssetObj.email,
                                                                security_role: viewingAssetObj.security_role,
                                                            };

                                                            fetch(apiUrl, {
                                                                method: 'POST',
                                                                cache: 'no-cache',
                                                                headers: {
                                                                    'Content-Type': 'application/json'
                                                                },
                                                                body: JSON.stringify({
                                                                    endpoint: 'vessels/share/add', v: apiVersion,
                                                                    device_id: localStorage.getItem('deviceID'),
                                                                    client_key: localStorage.getItem('clientKey'),
                                                                    payload: payload
                                                                }),
                                                            })
                                                            .then((response) => {
                                                                return response.text().then(function(text) {
                                                                    const result = JSON.parse(text);
                                                                    if ( result.error )
                                                                    {
                                                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                                        doNewAssetValidation(false);
                                                                        setViewingAsset(false);
                                                                        setPrimaryMsgType('warning');
                                                                        setPrimaryMsg(result.error);
                                                                    }
                                                                    else
                                                                    {
                                                                        setViewingAsset(false);
                                                                        doNewAssetValidation(false);
                                                                        toast.show((labels.current?.TOAST_UPDATE).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});

                                                                        if ( result.msg )
                                                                        {
                                                                            setPrimaryMsgType('success');
                                                                            setPrimaryMsg(result.msg);
                                                                        }

                                                                        setTimeout(()=>{
                                                                            setLastUpdate(now()); // forces app to refetch all account data
                                                                        }, 250);
                                                                    }
                                                                });
                                                            })
                                                            .catch(error => {
                                                                console.warn(error);
                                                                setViewingAsset(false);
                                                                doNewAssetValidation(false);
                                                                setPrimaryMsgType('warning');
                                                                if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                                                else { setPrimaryMsg('An error ocurred. See console log.'); }
                                                            });
                                                        }
                                                    }, 250);

                                                }}
                                            />
                                        </View>
                                    }
                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between md:min-h-[300px]`, labels?.current?.DOCUMENTS !== contentType && tw`hidden`]}>
                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`px-4 max-w-full`, documents[updateUserID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 290}px] md:h-[272px] mt-2`}
                                    >
                                        { documents[viewingAssetObj.id]?.length ? <View style={tw`md:flex-wrap md:flex-row md:justify-start md:items-start`}>{ documents[viewingAssetObj.id] }</View> : <View></View> }
                                    </ScrollView>
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={addNewDocumentPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setUpdateDocument(false)}}
                ></Pressable>

                <View style={tw`md:w-108 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setUpdateDocument(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateDocumentID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?.DOCUMENT }</Heading>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }

                                <View style={tw`md:flex-row md:flex-wrap md:justify-between`}>

                                    <Pressable
                                        style={tw`mt-2 w-full`}
                                        onPress={()=>{setDocumentTypeSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.TYPE +'*' }
                                            image={ documentType ? { uri: require('../svg/attachment.svg') } : { uri: require('../svg/attachment_gray.svg') }}
                                            // defaultValue={documentType}
                                            value={documentType ? documentTypeOptions.find(obj => {return obj.value === documentType})?.label : ''}
                                            validateGroup={newAssetValidation}
                                            required
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        placeholder={ labels.current?.NAME +'*' }
                                        image={ documentNameInput.current?.value ? { uri: require('../svg/name.svg') } : { uri: require('../svg/name_gray.svg') }}
                                        validatedValue={(value) => {setDocumentName(value)}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={documentName}
                                        passedRef={documentNameInput}
                                        required
                                    />

                                    <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        autoCapitalize="on"
                                        placeholder={ labels.current?.DETAIL }
                                        image={ documentDescInput.current?.value ? { uri: require('../svg/description.svg') } : { uri: require('../svg/description_gray.svg') }}
                                        pattern="^.{2,}$"
                                        validatedValue={(value) => {setDocumentDesc(value)}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={documentDesc}
                                        passedRef={documentDescInput}
                                    />

                                    { 0 === updateDocumentID &&
                                        <Pressable
                                            style={tw`mt-2 w-full`}
                                            onPress={()=>{documentInput.current.click();}}
                                        >
                                            <TextInput
                                                style={tw`pointer-events-none`}
                                                placeholder={ labels.current?.SELECT +' '+ labels.current?.DOCUMENT_FILE }
                                                image={ documentData ? { uri: require('../svg/upload.svg') } : { uri: require('../svg/upload_gray.svg') }}
                                                // defaultValue={documentType}
                                                passedRef={documentFileNameInput}
                                            />

                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                            />
                                        </Pressable>
                                    }

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        keyboardType="numeric"
                                        mask="99/99/9999"
                                        autoCapitalize="off"
                                        placeholder={labels.current?.DATE +" "+ labels.current?.EXPIRES +" MM/DD/YYYY"}
                                        image={ documentExpiration ? { uri: require('../svg/calendar_blue.svg') } : { uri: require('../svg/calendar_gray.svg') }}
                                        pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9]{2}$"
                                        allowPastDates={ true }
                                        validateGroup={newAssetValidation}
                                        validatedValue={(value) => {setDocumentExpiration(value)}}
                                        validateOnChange={true}
                                        stateValue={documentExpiration}
                                        passedRef={documentExpirationInput}
                                        value={documentExpirationMasked}
                                        onChangeText={(value)=>{setDocumentExpirationMasked(value)}}
                                        required
                                    />

                                </View>

                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />

                            <Button
                                style={tw`m-5`}
                                title={0 !== updateDocumentID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={documentName && documentType ? false : true}
                                validateGroup={newAssetValidation}
                                onPress={()=>{

                                    if ( newAssetValidation ) { return; }

                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        if ( ! documentType
                                            || ! documentName
                                        ){
                                            let errorMsg = [];

                                            if ( ! documentType )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.DOCUMENT +' '+labels.current?.TYPE); }

                                            if ( ! documentName )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.DOCUMENT +' '+labels.current?.NAME); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            if ( 0 === updateDocumentID )
                                            {
                                                uploadDocument(
                                                    documentData,
                                                    documentExt.current,
                                                    documentSize.current,
                                                    documentType,
                                                    documentName,
                                                    documentDesc || '',
                                                    MMDDYYYY_to_YYYYMMDD(documentExpirationMasked),
                                                    photoObjectType.current,
                                                    updateID,
                                                    yachtID,
                                                    toast,
                                                    0 === updateDocumentID ? labels.current?.TOAST_ADD.replace('{object}',labels.current?.DOCUMENT) : labels.current?.TOAST_UPDATE.replace('{object}',labels.current?.DOCUMENT),
                                                    {apiUrl:apiUrl, apiVersion:apiVersion, setLoggedIn:setLoggedIn},
                                                    (status)=>{
                                                        doNewAssetValidation(false);
                                                        if ( 'success' === status )
                                                        {
                                                            setLastUpdate(now());

                                                            setUpdateDocument(false);
                                                            setTimeout(()=>{
                                                                setUpdateDocumentID(0);
                                                                setDocumentData('');
                                                                if ( documentFileNameInput.current?.value ) { documentFileNameInput.current.value = ''; }
                                                                setDocumentType(0);
                                                                documentMimeType.current = '';
                                                                setDocumentName('');
                                                                if ( documentNameInput.current?.value ) { documentNameInput.current.value = ''; }
                                                                setDocumentDesc('');
                                                                if ( documentDescInput.current?.value ) { documentDescInput.current.value = ''; }
                                                                setDocumentExpiration('');
                                                                setDocumentExpirationMasked('');
                                                                if ( documentExpirationInput.current?.value ) { documentExpirationInput.current.value = ''; }
                                                            }, 1500);
                                                        }
                                                    }
                                                );
                                            }
                                            else {
                                                let payload = {
                                                    id: updateDocumentID,
                                                    name: documentName,
                                                    type_id: documentType,
                                                    details: documentDesc,
                                                    expiration_date: MMDDYYYY_to_YYYYMMDD(documentExpirationMasked)
                                                };

                                                fetch(apiUrl, {
                                                    method: 'POST',
                                                    cache: 'no-cache',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        endpoint: 'documents/update', v: apiVersion,
                                                        device_id: localStorage.getItem('deviceID'),
                                                        client_key: localStorage.getItem('clientKey'),
                                                        payload: payload
                                                    }),
                                                })
                                                .then((response) => {
                                                    return response.text().then(function(text) {
                                                        const result = JSON.parse(text);
                                                        if ( result.error )
                                                        {
                                                            if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                            setNewAssetMsgType('warning');
                                                            setNewAssetMsg(result.error);
                                                            doNewAssetValidation(false);
                                                        }
                                                        else
                                                        {
                                                            setUpdateDocument(false);
                                                            toast.show((0 !== updateDocumentID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?.DOCUMENT), {type: 'success', duration:2000});

                                                            setTimeout(()=>{
                                                                setLastUpdate(now()); // forces app to refetch all account data
                                                            }, 500);

                                                            setTimeout(()=>{
                                                                doNewAssetValidation(false);
                                                                setUpdateDocumentID(0);
                                                                setDocumentData('');
                                                                if ( documentFileNameInput.current?.value ) { documentFileNameInput.current.value = ''; }
                                                                setDocumentType(0);
                                                                documentMimeType.current = '';
                                                                setDocumentName('');
                                                                if ( documentNameInput.current?.value ) { documentNameInput.current.value = ''; }
                                                                setDocumentDesc('');
                                                                if ( documentDescInput.current?.value ) { documentDescInput.current.value = ''; }
                                                                setDocumentExpiration('');
                                                                setDocumentExpirationMasked('');
                                                                if ( documentExpirationInput.current?.value ) { documentExpirationInput.current.value = ''; }
                                                            }, 1500);
                                                        }
                                                    });
                                                })
                                                .catch(error => {
                                                    console.warn(error);
                                                    setNewAssetMsgType('warning');
                                                    if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                    else { setNewAssetMsg('An error ocurred. See console log.'); }
                                                    doNewAssetValidation(false);
                                                });
                                            }
                                        }
                                    }, 250);


                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={viewDocumentAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingDocument(false)}}
                ></Pressable>

                <View style={tw`md:w-172 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingDocument(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?.DOCUMENT }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={2}>{ viewingDocumentObj.type_id ? documentTypeOptions.find(obj => {return obj.value == viewingDocumentObj.type_id})?.label : '' }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                <Tag style={tw`self-end mb-0`} color={viewingDocumentObj.expiration_color}>{ viewingDocumentObj.expiration_tag }</Tag>

                                { null !== viewingDocumentObj.expiration_date &&
                                    <View style={tw`flex-row items-center`}>
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/date.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mr-2`}
                                        />
                                        <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ ( is_date_in_past(viewingDocumentObj.expiration_tag) ? labels?.current?.EXPIRED : labels?.current?.EXPIRES ) + ' ' + YYYYMMDD_to_DMJYYYY( viewingDocumentObj.expiration_date ) }</Span>
                                    </View>
                                }
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View style={tw`flex-col`}>
                                    <Span style={tw`font-bold`}>{ viewingDocumentObj.type_id ? documentTypeOptions.find(obj => {return obj.value == viewingDocumentObj.type_id})?.label : '' }</Span>

                                    { null !== viewingDocumentObj.expiration_date &&
                                        <View style={tw`flex-row items-center`}>
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/date.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 mr-2`}
                                            />
                                            <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ ( is_date_in_past(viewingDocumentObj.expiration_tag) ? labels?.current?.EXPIRED : labels?.current?.EXPIRES ) + ' ' + YYYYMMDD_to_DMJYYYY( viewingDocumentObj.expiration_date ) }</Span>
                                        </View>
                                    }
                                </View>

                                <View style={tw`self-center`}>
                                    <Tag style={tw`self-end mb-0`} color={viewingDocumentObj.expiration_color}>{ viewingDocumentObj.expiration_tag }</Tag>
                                </View>
                            </View>
                            <HorizontalRule style={tw`mb-4 md:hidden`} />

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5`}
                            >
                                <View style={tw`md:flex-row md:flex-wrap md:justify-between content-start md:min-h-[300px]`}>
                                    <View style={tw`md:flex-row md:flex-wrap md:px-1 w-full max-w-full`}>
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.NAME }
                                            labelImage={{ uri: require('../svg/name.svg') }}
                                            value={viewingDocumentObj.name}
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.TYPE }
                                            labelImage={{ uri: require('../svg/attachment.svg') }}
                                            value={ viewingDocumentObj.type_id ? documentTypeOptions.find(obj => {return obj.value == viewingDocumentObj.type_id})?.label : '' }
                                        />
                                        <SpecsCard
                                            style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                            label={ labels.current?.DATE +" "+ labels.current?.EXPIRES }
                                            labelImage={{ uri: require('../svg/calendar_blue.svg') }}
                                            value={ viewingDocumentObj.expiration_date ? YYYYMMDD_to_MMDDYYYY(viewingDocumentObj.expiration_date) : 'N/A' }
                                        />
                                        { '' !== viewingDocumentObj.uri && null !== viewingDocumentObj.uri &&
                                            <SpecsCard
                                                style={[tw`md:mr-2`, {width: tw.prefixMatch('md') ? 'calc(50% - 8px)' : '100%'}]}
                                                label={ labels.current?.VIEW +' '+ labels.current?.DOCUMENT_FILE }
                                                labelImage={{ uri: require('../svg/url.svg') }}
                                                onPress={() => {  window.open(viewingDocumentObj.uri, '_blank'); }}
                                            />
                                        }
                                    </View>

                                    <View style={tw`w-full p-1 max-w-full`}>
                                        <Heading hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 5} style={tw`mt-5`}>{ labels.current?.DETAILS }</Heading>
                                        <Span>{ viewingDocumentObj.details }</Span>
                                    </View>
                                </View>

                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={documentTypeSelectVisible}
                setActive={setDocumentTypeSelectVisible}
                selectedValue={documentType}
                options={documentTypeOptions}
                onValueChange={(value) => {setDocumentType(value)}}
                scrollable={documentTypeOptions.length > 8}
                searchable={documentTypeOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.SHARE_ROLE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={shareRoleSelectVisible}
                setActive={setShareRoleSelectVisible}
                selectedValue={newShareRole}
                options={roleOptions.filter((role)=>{return role.value !== 'ROLE_OWNER'})}
                onValueChange={(value) => {setNewShareRole(value)}}
                scrollable={false}
            />

            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>Are you sure?</Heading>
                <Span>Are you sure you want to delete this share?</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-blue`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-blue`}
                        styleHoverText={tw`text-blue`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'vessels/share/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: {
                                       vessel_id: parseInt(yachtID),
                                       user_id: parseInt(modalAffectID),
                                    }
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        setPrimaryMsg(result.error);
                                        setPrimaryMsgType('warning');
                                        setModalAffectID(0);
                                        setModalActive(false);
                                    }
                                    else
                                    {
                                        setModalAffectID(0);
                                        setModalActive(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                setPrimaryMsgType('warning');
                                if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                else { setPrimaryMsg('An error ocurred. See console log.'); }
                            });
                        }}
                    />
                </View>
            </Modal>

            <Modal
                active={deleteFileModalActive}
                setActive={setDeleteFileModalActive}
                doWhileExit={()=>{ documentMimeType.current = ''; }}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.DELETE_CONFIRM_TITLE }</Heading>
                <Span>{ labels.current?.DELETE_CONFIRM_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-blue`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-blue`}
                        styleHoverText={tw`text-blue`}
                        onPress={()=>{setDeleteFileModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');
                            let payload = {
                                object_type: photoObjectType.current,
                                object_id: updateUserID,
                                vessel_id: yachtID,
                                id: deleteFileModalAffectID,
                            };

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'documents/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: payload
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        toast.show(result.error, {type: 'danger', duration:10000});
                                    }
                                    else
                                    {
                                        setDeleteFileModalAffectID('');
                                        setDeleteFileModalActive(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels.current?._ASSET+' '+labels.DOCUMENT), {type: 'success', duration:2000});
                                        documentMimeType.current = '';

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                if ( ! window.navigator.onLine ) { toast.show('Internet connection failed. Please try again.', {type: 'danger', duration:10000}); }
                                else { toast.show('An error ocurred. See console log.', {type: 'danger', duration:10000}); }
                            });
                        }}
                    />
                </View>
            </Modal>
        </View>
    )
}

export default ShareYachtView;
